<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Detail Log Modal
    </template>
    <template slot="body">
      <md-card-content v-if="item.students">
        <md-table table-header-color="green">
          <h4>Students</h4>
          <div />
          <md-table-row>
            <md-table-head>Students Number</md-table-head>
            <md-table-head>Full Name</md-table-head>
            <md-table-head>Synced</md-table-head>
            <md-table-head />
          </md-table-row>
          <md-table-row
            v-for="(detail, index) in item.students"
            :key="index"
          >
            <md-table-cell>
              {{ detail.student_number }}
            </md-table-cell>
            <md-table-cell>
              {{ detail.full_name }}
            </md-table-cell>
            <md-table-cell>
              {{ detail.synced }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-content v-if="item.staff">
        <md-table table-header-color="green">
          <h4>Staff</h4>
          <div />
          <md-table-row>
            <md-table-head>Staff Number</md-table-head>
            <md-table-head>Full Name</md-table-head>
            <md-table-head>Synced</md-table-head>
            <md-table-head />
          </md-table-row>
          <md-table-row
            v-for="(detail, index) in item.staff"
            :key="index"
          >
            <md-table-cell>
              {{ detail.staff_number }}
            </md-table-cell>
            <md-table-cell>
              {{ detail.full_name }}
            </md-table-cell>
            <md-table-cell>
              {{ detail.synced }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style></style>
