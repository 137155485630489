<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h2 class="d-flex align-items-center">
        Server Status

        <div
          v-if="!serverStatus"
          class="badge badge-danger ml-4"
        >
          OFFLINE
        </div>
        <div
          v-else
          class="badge badge-success ml-4"
        >
          ONLINE
        </div>
      </h2>
    </div>

    <div class="md-layout-item md-size-100">
      <h2 class="mb-2">
        Registration Machines
      </h2>

      <div class="machines-container">
        <CardMachinesMap
          v-for="(item, index) in registrationMachines"
          :key="index"
          :item="item"
        />
      </div>
    </div>

    <div class="md-layout-item md-size-100">
      <h2 class="mb-2">
        Attendance Machines
      </h2>

      <div class="machines-container">
        <CardMachinesMap
          v-for="(item, index) in classroomMachines"
          :key="index"
          :item="item"
        />
      </div>
    </div>

    <div class="md-layout-item md-size-100">
      <h2 class="mb-2">
        Staff Machines
      </h2>

      <div class="machines-container">
        <CardMachinesMap
          v-for="(item, index) in staffMachines"
          :key="index"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CardMachinesMap from './CardMachinesMap.vue';

export default {
  components: {
    CardMachinesMap,
  },
  data() {
    return {
      classroomMachines: [],
      registrationMachines: [],
      staffMachines: [],
      serverStatus: false,
    };
  },
  mounted() {
    this.fillMachinesUp();
  },
  methods: {
    fillMachinesUp() {
      this.requestAsync('biometric/machines', 'get', null).then(({ data }) => {
        this.classroomMachines = data.class_m;
        this.registrationMachines = data.regis_m;
        this.staffMachines = data.staff_m;
        this.serverStatus = moment(
          data.server_status.last_connection,
        ).isSameOrAfter(moment().subtract(5, 'minute'));
      });
    },
  },
};
</script>
