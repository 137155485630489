<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Student Form Log
    </template>
    <template slot="body">
      <md-list>
        <md-list-item
          v-for="(log, index) in data.data"
          :key="index"
        >
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="d-flex align-items-center">
              <small
                :class="{
                  'green-circle': log.success,
                  'red-circle': !log.success,
                }"
              />
              <small class="ml-3">{{ log.message }}</small>
            </span>
            <small>{{ dateTimeFormat(log.created_at) }}</small>
          </div>
        </md-list-item>
      </md-list>
      <Pagination
        class="justify-content-center"
        :per-count="data.last_page"
        :per-page="data.per_page"
        :total="data.total"
        :value="data.current_page"
        @input="fillLogsUp"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  components: {
    Pagination,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    data: {},
  }),
  computed: {
    machineType() {
      if (!this.item.details) return 'registration';
      if (this.item.details.staff) return 'staff';
      return 'classroom';
    },
    machineId() {
      return this.item[`${this.machineType}_machine_id`];
    },
  },
  mounted() {
    this.fillLogsUp(1);
  },
  methods: {
    fillLogsUp(NextPage) {
      this.requestAsync(`biometric/machines/${this.machineId}/log`, 'get', {
        page: NextPage,
        type: this.machineType,
      }).then(({ data }) => {
        this.data = data.data;
      }).catch((error) => console.error(error));
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style scoped>
.green-circle, .red-circle {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.green-circle {
  background: greenyellow;
}
.red-circle {
  background: red;
}
</style>
