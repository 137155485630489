<template>
  <div>
    <md-card
      class="machine-card"
      :class="{
        borderred: item.synced === 'YES',
        bordergreen: item.synced === 'NO',
      }"
    >
      <md-card-header>
        <h3>{{ item.name }}</h3>
        <template v-if="!item.registration_machine_id">
          <md-button
            v-if="item.synced === 'YES'"
            class="md-success md-sm mr-1 bg-red"
            disabled
          >
            SYNCED
          </md-button>
          <md-button
            v-else
            class="md-danger md-sm mr-1 bg-red"
            disabled
          >
            UNSYNCED
          </md-button>
        </template>
      </md-card-header>
      <md-divider />
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <p><b>Machine IP: </b>{{ item.ip }}</p>
            <p>
              <b>Status: <span v-html="status(item.last_connection)" /></b>
            </p>
            <p v-if="item.last_registration">
              <b>Last Registration:</b>
              {{ dateTimeFormat(item.last_registration) }}
            </p>
            <p v-if="item.last_registration_students">
              <b>Last Registration Students:</b>
              {{ item.last_registration_students }}
            </p>
            <p v-if="item.details && item.details.students">
              <b>Total Students:</b> {{ item.details.students.length }}
            </p>
            <p v-if="item.details && item.details.staff">
              <b>Total Staff:</b> {{ item.details.staff.length }}
            </p>
            <p v-if="item.synced">
              <b>Synchronized: </b>{{ item.synced }}
            </p>
          </div>
        </div>
      </md-card-content>
      <div class="d-flex px-4 pb-3 gap-1">
        <md-button
          v-if="machineType !== 'registration'"
          class="md-danger md-sm flex-1"
          @click="clear"
        >
          CLEAR
        </md-button>
        <md-button
          class="md-info md-sm flex-1"
          @click="sync"
        >
          SYNC
        </md-button>
        <md-button
          class="md-info md-sm flex-1"
          @click="showLogs = true"
        >
          LOGS
        </md-button>

        <md-button
          v-if="item.details"
          class="md-info md-sm flex-1"
          @click="showDetails = true"
        >
          DETAILS
        </md-button>
      </div>
    </md-card>
    <LogModal
      v-if="showLogs"
      :item="item"
      @close="showLogs = false"
    />
    <DetailsModal
      v-if="showDetails"
      :item="item.details"
      @close="showDetails = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import LogModal from './LogModal.vue';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    LogModal,
    DetailsModal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showLogs: false,
      showDetails: false,
    };
  },
  computed: {
    machineType() {
      if (!this.item.details) return 'registration';
      if (this.item.details.staff) return 'staff';
      return 'classroom';
    },
    machineId() {
      return this.item[`${this.machineType}_machine_id`];
    },
  },
  methods: {
    clear() {
      this.fireConfirm(
        'Removing Biometric data',
        'This will remove all the biometric data from this machine. Are you sure?',
      )
        .then(() => {
          this.requestAsync(`biometric/machines/${this.machineId}/clear`, 'delete', {
            type: this.machineType,
          }).then((data) => {
            this.fireSuccess(data);
          });
        });
    },
    sync() {
      this.requestAsync(`biometric/machines/${this.machineId}/sync`, 'post', {
        type: this.machineType,
      }).then((data) => {
        this.fireSuccess(data);
      });
    },
    status(last_connection) {
      return moment(last_connection).isSameOrAfter(
        moment().subtract(5, 'minute'),
      )
        ? "<b class='text-success'>Online</b>"
        : "<b class='text-danger'>Offline</b>";
    },
  },
};
</script>
